import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import area from '../../assets/icons/area.svg';
import Beratungsstunden from '../../assets/icons/beratungsstunden.svg';
import Beratungstaetigkeiten from '../../assets/icons/beratungstaetigkeiten.svg';
import CalenderIcon from '../../assets/icons/calenderTime.svg';
import ChangeUserData from '../../assets/icons/changeUserData.svg';
import HomeScreenButton from '../components/Common/HomeScreenButton';
import { useGlobalContext } from '../contexts/globalContext';
import SCREEN from '../utils/constants/SCREEN';

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    gap: 10,
    justifyContent: 'center',
  },
});

export default function Home({ navigation, route }) {
  const { setActiveTab, auth, setMemberName, setHistoryMemberID } = useGlobalContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(true);

  const navigationMap = {
    [CalenderIcon]: { destination: SCREEN.HOURS, tab: 1 },
    [Beratungsstunden]: { destination: SCREEN.ADD_HOURS, tab: '', params: 'home' },
    [ChangeUserData]: { destination: SCREEN.PROFILE, tab: 2 },
    [Beratungstaetigkeiten]: { destination: SCREEN.CONSULTING_ACTIVITIES },
    [area]: { destination: SCREEN.CONSULTING_PLACES },
  };

  const handleClick = (imageSource) => {
    const { destination, tab, params } = navigationMap[imageSource] || {
      destination: SCREEN.HOME,
      tab: 0,
    };
    navigation.navigate(destination, { params });
    setActiveTab(tab);
    setMemberName(null);
    setHistoryMemberID(null);
  };


  return (
    <ScrollView style={{ marginTop: 20, marginBottom: 20 }}>
      <View style={styles.container}>
            <HomeScreenButton
              imageSource={Beratungsstunden}
              text="Beratungsstunden erfassen"
              onPress={() => handleClick(Beratungsstunden)}
            />
            <HomeScreenButton
              imageSource={CalenderIcon}
              text="Stundenübersicht"
              onPress={() => handleClick(CalenderIcon)}
            />
            <HomeScreenButton
              imageSource={ChangeUserData}
              text="Nutzerdaten ändern"
              onPress={() => handleClick(ChangeUserData)}
            />
            <HomeScreenButton
              imageSource={Beratungstaetigkeiten}
              text="Beratungstätigkeiten"
              onPress={() => handleClick(Beratungstaetigkeiten)}
            />
            <HomeScreenButton
              imageSource={area}
              text="Veranstaltungsorte"
              onPress={() => handleClick(area)}
            />
      </View>
    </ScrollView>
  );
}
