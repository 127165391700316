const SCREEN = {
  LOGIN: 'LOGIN',
  INNER_APP: 'INNER_APP',
  HOME: 'HOME',
  PROFILE: 'PROFILE',
  HOURS: 'HOURS',
  ADD_HOURS: 'ADD_HOURS',
  EDIT_CONSULTING_SESSION: 'EDIT_CONSULTING_SESSION',
  COPY_CONSULTING_SESSION: 'COPY_CONSULTING_SESSION',
  CONSULTING_ACTIVITIES: 'CONSULTING_ACTIVITIES',
  CONSULTING_PLACES: 'CONSULTING_PLACES',
};
export default SCREEN;
