import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
} from '@mui/material';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import {DataGrid, GridActionsCellItem, deDE} from '@mui/x-data-grid';
import {useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import MenuItem from '@mui/material/MenuItem';
import FadeInHeaderImage from '../components/Common/FadeInHeaderImage';
import {useResource} from '../hooks/useResource';
import ConsultingActivityService from '../services/ConsultingActivityService';
import ConsultingSessionService from '../services/ConsultingSessionService';
import ConsultingActivitiesEditModal from '../components/ConsultingActivities/ConsultingActivitiesEditModal';
import ConsultingActivitiesNewCAModal from '../components/ConsultingActivities/ConsultingActivitiesNewCAModal';
import ConsultingActivitiesDeleteModal from '../components/ConsultingActivities/ConsultingActivitiesDeleteModal';
import {useTheme} from '@mui/material/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    position: 'absolute',
    top: 80,
    left: 0,
    right: 0,
    bottom: 0,
    marginBottom: 80,
  },

  footerContainer: {
    position: 'absolute',
    bottom: 16,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 16,
    alignItems: 'center',
  },
  noDataContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
  },
  noData: {
    fontSize: 20,
  },
});

export default function ConsultingActivities({navigation, route}) {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [openNewCADialog, setOpenNewCADialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  // Beratungskategorien holen
  const {data: consultingSessionCategories} = useResource({
    name: 'activity-categories',
    cacheName: 'api-activity-categories-cache',
  });

// Beratungstätigkeiten holen
  const {
    data: activities,
    loading: activitiesLoading,
    reload: activitiesReload,
  } = useResource({
    name: 'activities',
    defaultData: [],
    cacheName: 'api-activities-cache',
  });

  const columns = useMemo(() => [
    {
      field: 'uid',
      headerName: 'ID',
      width: 70,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: 'Katgorie',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({row}) => {
        //const isDisabled = parseInt(row['__inUse']) != 0;
        return [
          <GridActionsCellItem
              icon={<EditIcon/>}
              label="Edit"
              color="inherit"
              //disabled={isDisabled}
              onClick={() => handleOpenEditModal(row)}
          />,
          <GridActionsCellItem
              icon={<DeleteIcon/>}
              label="Delete"
              color="inherit"
              //disabled={isDisabled}
              onClick={() => handleOpenDeleteModal(row)}
          />,
        ];
      },
    },
  ], []);

  useEffect(() => {
    const mappedRows = activities?.map((item, index) => ({
      id: index,
      uid: item.uid || '',
      name: item.name || '',
      categoryName: item.categoryName || '',
      __inUse: item.__inUse || false,
    }));
    setRows(mappedRows);
  }, [activities]);

  // open newCA Modal
  const handleOpenNewCAModal = () => {
    setOpenNewCADialog(true);
    setErrorMessage(null);
  };

  // close newCA Modal
  const handleCloseNewCAModal = () => {
    setOpenNewCADialog(false);
    setErrorMessage(null);
  };

  // open EditModal
  const handleOpenEditModal = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setErrorMessage(null);

  };

  // close EditModal
  const handleCloseEditModal = () => {
    setOpenEditDialog(false);
    setErrorMessage(null);
  };

  // open DeleteModal
  const handleOpenDeleteModal = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  // close DeleteModal
  const handleCloseDeleteModal = () => {
    setOpenDeleteDialog(false);
  };

  return (

      <View style={styles.container}>
        <View>
          <FadeInHeaderImage heading="Beratungstätigkeiten" navigation={navigation}/>
        </View>
        <View style={styles.contentContainer}>
          {activitiesLoading ? ( // Überprüfe, ob Daten geladen werden
              <View style={styles.noDataContainer}>
                <Skeleton variant="text" height={20} width="90%"/>
              </View>
          ) : activities && activities.length > 0 ? (
              <DataGrid
                  localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                  rows={rows}
                  columns={columns}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  components={{
                    NoRowsOverlay: () => (
                        <View style={styles.noDataContainer}>
                          <Text style={styles.noData}>Es wurden keine Daten gefunden.</Text>
                        </View>
                    ),
                  }}
                  sx={{
                    '& .MuiDataGrid-footerContainer': {
                      justifyContent: 'center',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: ' none',
                    },
                  }}
              />
          ) : (
              <View style={styles.noDataContainer}>
                <Text style={styles.noData}>Es wurden keine Daten gefunden.</Text>
              </View>
          )}
        </View>

        <View style={styles.footerContainer}>
          <Fab style={{
            backgroundColor: theme.palette.green,
          }} sx={{
            '& .MuiSvgIcon-root': {
              color: theme.palette.white,
            },
          }} onClick={handleOpenNewCAModal}>
            <AddIcon/>
          </Fab>
        </View>


        <ConsultingActivitiesEditModal
            row={selectedRow}
            open={openEditDialog}
            onClose={handleCloseEditModal}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            consultingSessionCategories={consultingSessionCategories}
            activitiesReload={activitiesReload}

        />
        <ConsultingActivitiesDeleteModal
            row={selectedRow}
            open={openDeleteDialog}
            onClose={handleCloseDeleteModal}
            activitiesReload={activitiesReload}

        />
        <ConsultingActivitiesNewCAModal
            open={openNewCADialog}
            onClose={handleCloseNewCAModal}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            activities={activities}
            consultingSessionCategories={consultingSessionCategories}
            activitiesReload={activitiesReload}
        />
      </View>
  );
}