import {useEffect, useState} from 'react';
import ConsultingActivityService from '../../services/ConsultingActivityService';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {StyleSheet, Text} from 'react-native';
import {useTheme} from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

export default function ConsultingActivitiesNewCAModal({errorMessage, setErrorMessage, open, onClose, activities, consultingSessionCategories, activitiesReload}) {

  const theme = useTheme();
  const styles = StyleSheet.create({

    consultingCategory: {
      marginTop: 8,
    },
    errorMessage: {
      color: theme.palette.red,
      fontSize: 12,
    },
    btngroup: {
      justifyContent: 'center',
      paddingBottom: 16,
    },
  });
  const [newConsultingActivity, setnewConsultingActivity] = useState('');
  const [selectedConsultingSessionCategory, setSelectedConsultingSessionCategory] = useState('');

  const handleInputChange = (event) => {
    if (event.target.value.length >= 30) {
      setErrorMessage('Tätigkeit darf nicht mehr als 30 Zeichen haben');
    } else {
      setnewConsultingActivity(event.target.value);
      setErrorMessage(null);
    }
  };

  const handleSaveActivity = async () => {
    try {
      const res = await ConsultingActivityService.newConsultingActivity(
          newConsultingActivity.trim(),
          selectedConsultingSessionCategory,
      );
      setnewConsultingActivity(newConsultingActivity);

      if (res.status === '200') {
        onClose();
        activitiesReload();
      }

    } catch (error) {
      console.error(error);
      setErrorMessage(error.response.data.error_message);

    }
  };

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Beratungstätigkeit hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hier können Sie eine neue Beratungstätigkeit hinzufügen
          </DialogContentText>

          <TextField
              required
              margin="dense"
              id="activitiy"
              label="Beratungstätigkeit"
              type="text"
              fullWidth
              variant="standard"
              value={newConsultingActivity}
              onChange={handleInputChange}
              inputProps={{maxLength: 30}}
              error={errorMessage !== null}
          />

          <FormControl variant="standard" fullWidth style={styles.consultingCategory} required>
            <InputLabel id="consulting-category-activity-label">Beratungskategorie</InputLabel>
            <Select
                labelId="consulting-category-activity-label"
                id="consulting-category-activity"
                label="consulting-category"
                onChange={(event) => {
                  setSelectedConsultingSessionCategory(event.target.value);
                }}
                value={selectedConsultingSessionCategory}
                required
            >
              {Array.isArray(consultingSessionCategories) && consultingSessionCategories.map(({uid, name}) => {
                return <MenuItem value={uid} key={uid}> {name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          {errorMessage !== null &&
              <Text
                  style={styles.errorMessage}>
                {errorMessage}
              </Text>
          }
        </DialogContent>
        <DialogActions style={styles.btngroup}>
          <Button variant="contained" color="primary" onClick={onClose} startIcon={<CancelIcon/>}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveActivity} startIcon={<SaveIcon/>}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
  );

}