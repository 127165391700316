import MPRestService from './MPRestService';

class UserService extends MPRestService {
  endpoint;

  constructor() {
    super();
    this.endpoint = '/users';
  }

  /**
   *
   * @param {{uid, email, password}} updateData
   * @returns
   */
  async update(updateData) {
    if (!updateData.uid) {
      return null;
    }
    const newID = await this._patch(this.endpoint + '/' + updateData.uid, updateData);

    return newID;
  }
}

export default UserService;
