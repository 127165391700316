// @ts-nocheck
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {deDE} from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useTheme} from '@mui/material/styles';
import {OfflineIndicator} from '../../Common/OfflineIndicator';
import {useGlobalContext} from '../../contexts/globalContext';
import H from '../../helper/helper';
import {useNetworkStatus} from '../../hooks/useNetworkStatus';
import {useResource} from '../../hooks/useResource';
import ConsultingSessionService from '../../services/ConsultingSessionService';
import SCREEN from '../../utils/constants/SCREEN';
import filterMemberOptions from '../../utils/filterMemberOptions';
import {OverlapSessionIndicator} from '../AddHours/OverlapSessionIndicator';
import SaveIcon from '@mui/icons-material/Save';


const ConsultingSessionEnterForm = ({navigation, route}) => {
  const theme = useTheme();
  const genericButtonStyle = {
    width: 350,
    height: 50,
    alignSelf: 'center',
    color: theme.palette.white,
    marginBottom: 15,
  };

  const styles = StyleSheet.create({
    savebtn: {
      ...genericButtonStyle,
      backgroundColor: theme.palette.green,
    },
    cancelbtn: {
      ...genericButtonStyle,
      backgroundColor: '#84868a',
    },
  });
  const {auth, setMemberName, setHistoryMemberID} = useGlobalContext();
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().toISOString()));
  const [startDate, setstartDate] = useState(null);
  const [minutes, setMinutes] = useState('');
  const [hours, setHours] = useState('');
  const [activityUID, setActivityUID] = useState([]);
  const [placeUID, setPlaceUID] = useState([]);
  const [memberUIDS, setMemberUIDS] = useState([]);
  const [cultureUID, setCultureUID] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showErrorTime, setShowErrorTime] = useState(false);
  const [showErrorMember, setShowErrorMember] = useState(false);
  const [showOfflineDialog, setShowOfflineDialog] = useState(false);
  const [comment, setComment] = useState('');

  const helper = new H();
  const isConnected = useNetworkStatus();

  const {
    data: overlappingSessions,
    loading: overlappingSessionsLoading,
    reload: overlappingSessionsReload,
  } = useResource({
    name: 'consulting-sessions',
    enabled: false,
    filter: {
      memberIDS: memberUIDS,
      onDate: dayjs(selectedDate).format('YYYY-MM-DD'),
    },
  });

  const {data: memberPersonalDataAutoComplete, loading: memberPersonalDataAutoCompleteLoading} = useResource({
    name: 'memberPersonalDataAutoComplete',
    cacheName: 'api-members-cache',
  });

  const {data: activities, loading: activitiesLoading} = useResource({
    name: 'activities',
    defaultData: [],
    cacheName: 'api-activities-cache',
  });

  const {data: cultures, loading: culturesLoading} = useResource({
    name: 'cultures',
    defaultData: [],
    cacheName: 'api-cultures-cache',
  });

  const {data: places, loading: placesLoading} = useResource({
    name: 'places',
    defaultData: [],
    cacheName: 'api-places-cache',
  });

  useEffect(() => {
    const checkDataAndConnection = () => {
      if (isConnected) {
        const offlineData = localStorage.getItem('offlineData');
        if (offlineData) {
          setShowOfflineDialog(true);
        }
      }
    };

    checkDataAndConnection();
  }, [isConnected]);

  // This effect loads potentially overlapping sessions that was logged by another user
  useEffect(() => {
    if (!!selectedDate && memberUIDS.length > 0) {
      // selectedDate and memberUIDS are required 
      overlappingSessionsReload();
    }
  }, [selectedDate, memberUIDS]);

  const handleChangeDate = (newValue) => {
    setSelectedDate(newValue);
    setstartDate(newValue ? newValue.$d : null);
  };

  const handleSaveHours = async () => {
    let updatedCountErrors = 0;

    if (!(memberUIDS && memberUIDS.length > 0)) {
      setShowErrorMember(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorMember(false);
    }

    if (!hours && !minutes) {
      setShowErrorTime(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorTime(false);
    }

    if (cultureUID.length === 0) {
      setShowError(true);
      updatedCountErrors += 1;
    } else {
      setShowError(false);
    }

    if (updatedCountErrors === 0) {
      //const formattedStartDate = helper.formatDateToYYYYMMDD(startDate);
      const newConsultingSessionData = [
        {
          user_uid: auth.uid,
          member_uid: memberUIDS,
          activity_uid: activityUID,
          place_uid: placeUID,
          culture_uid: cultureUID,
          comment,
          startDate: selectedDate,
          hours,
          minutes,
        },
      ];

      const promises = [];
      for (let i = 0; i < memberUIDS.length; i++) {
        const memberId = memberUIDS[i];
        const consultingSession = {
          user_uid: auth.uid,
          member_uid: memberId,
          activity_uid: activityUID,
          place_uid: placeUID,
          culture_uid: cultureUID,
          comment,
          startDate: selectedDate,
          hours,
          minutes,
        };
        promises.push(ConsultingSessionService.newConsultingSession(consultingSession));
      }

      setMemberName(null);
      setHistoryMemberID(null);

      if (isConnected) {
        await handleSaveHoursWhenOnline(promises);
      } else {
        // offline
        await handleSaveHoursWhenOffline(newConsultingSessionData);
      }
    }
  };

  const handleSaveHoursWhenOnline = async (promises) => {
    await Promise.all(promises);
    navigation.navigate(SCREEN.HOURS, {});
  };

  const handleSaveHoursWhenOffline = async (data) => {
    localStorage.removeItem('offlineData');
    localStorage.setItem('offlineData', JSON.stringify(data));
    navigation.navigate(SCREEN.HOURS, {});
  };

  const handleOfflineDialogYes = async () => {
    // Logic to handle reviewing data
    setShowOfflineDialog(false);
    let offlineData = localStorage.getItem('offlineData');
    offlineData = JSON.parse(offlineData);

    for (let i = 0; i < offlineData[0].member_uid.length; i++) {
      const memberId = offlineData[0].member_uid[i];
      const {activity_uid, culture_uid, place_uid, comment, startDate, hours, minutes} = offlineData[0];
      //const formattedStartDate = helper.formatDateToYYYYMMDD(new Date(startDate));
      const consultingSession = {
        user_uid: auth.uid,
        member_uid: memberId,
        activity_uid,
        place_uid,
        culture_uid,
        comment,
        startDate: selectedDate,
        hours,
        minutes,
      };
      await ConsultingSessionService.newConsultingSession(consultingSession);
      navigation.navigate(SCREEN.HOURS, {});
    }
    localStorage.removeItem('offlineData');
  };

  const handleOfflineDialogNo = () => {
    localStorage.setItem('offlineData', null);
    localStorage.removeItem('offlineData');
    setShowOfflineDialog(false);
  };

  return (
      <View style={{marginHorizontal: 10}}>
        <View>
          <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2}}>
            <Typography variant="h5">Beratungsstunden erfassen</Typography>
          </Box>
          <Box sx={{mx: 2, mb: 2}}>
            {!isConnected && <OfflineIndicator/>}

            {!!overlappingSessions.length &&
                <OverlapSessionIndicator
                    activities={activities}
                    cultures={cultures}
                    places={places}
                    members={memberPersonalDataAutoComplete}
                    consultingSessions={overlappingSessions}
                />
            }

            <FormControl fullWidth>
              <Autocomplete
                  multiple
                  autoHighlight
                  blurOnSelect
                  noOptionsText="Keine Ergebnisse gefunden"
                  filterOptions={filterMemberOptions}
                  options={memberPersonalDataAutoComplete}
                  getOptionLabel={(value) => {
                    if (memberPersonalDataAutoCompleteLoading) {
                      return value;
                    }

                    const memberData = memberPersonalDataAutoComplete.find((item) => {
                      let idToFind = !isNaN(value) ? parseInt(value, 10) : null;
                      if (!idToFind) {
                        idToFind = value?.memberID.toString();
                      }
                      return item?.memberID.toString() === idToFind.toString();
                    });

                    if (memberData) {
                      const {name, street, city, zip, memberID} = memberData;
                      return `${name}`;
                    } else {
                      return 'Fehler';
                    }
                  }}
                  renderOption={(props, option, index) => {
                    const optionStyle = {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                    };

                    const nameStyle = {
                      fontSize: '16px',
                    };

                    const addressStyle = {
                      fontSize: '12px',
                    };

                    return (
                        <li {...props} key={option.memberID}>
                          <div style={optionStyle}>
                      <span style={nameStyle}>
                        {option?.name} <strong>[{option?.memberID}]</strong>
                      </span>
                            <span style={addressStyle}>
                        {option?.street}
                              {option?.zip ? `, ${option?.zip}` : ''}
                              {option?.city ? ` ${option?.city}` : ''}
                      </span>
                          </div>
                        </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Mitglied" error={showErrorMember} helperText={showErrorMember ? 'Bitte wählen Sie mindestens ein Mitglied aus.' : ''}/>}
                  onChange={(event, options) => {
                    setMemberUIDS(options.map((option) => option?.memberID));
                  }}
              />
            </FormControl>
          </Box>
        </View>
        <View style={{flex: 1}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de" localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
            <MobileDatePicker
                disableFuture
                label="Datum"
                format="DD.MM.YYYY"
                value={selectedDate}
                onChange={handleChangeDate}
                closeOnSelect
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                          <InputAdornment position="end">
                            <CalendarMonthOutlinedIcon/>
                          </InputAdornment>
                      ),
                    },
                  },
                }}
                sx={{mx: 2, mb: 2}}
            />
          </LocalizationProvider>
        </View>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <Box sx={{ml: 2, mr: 1, mb: 2}}>
              <FormControl fullWidth error={showErrorTime}>
                <InputLabel id="hours-select-label">Stunden</InputLabel>
                <Select
                    labelId="hours-select-label"
                    id="hours-select"
                    value={hours}
                    label="Stunden"
                    onChange={(event) => {
                      setHours(event.target.value);
                    }}>
                  {[...Array(25)].map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </View>
          <View style={{flex: 1}}>
            <Box sx={{mr: 2, ml: 1, mb: 2}}>
              <FormControl fullWidth error={showErrorTime}>
                <InputLabel id="minutes-select-label">Minuten</InputLabel>
                <Select
                    labelId="minutes-select-label"
                    id="minutes-select"
                    value={minutes}
                    label="Minuten"
                    onChange={(event) => {
                      setMinutes(event.target.value);
                    }}>
                  {[0, 15, 30, 45].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </View>
        </View>
        <View style={{flex: 1}}>
          <Box sx={{mx: 2, mb: 2}}>
            <FormControl fullWidth>
              <Autocomplete
                  autoHighlight
                  blurOnSelect
                  noOptionsText="Keine Ergebnisse gefunden"
                  options={activities.sort((a, b) => a.categoryID - b.categoryID)}
                  getOptionLabel={(activity) => activity.name}
                  filterOptions={(options, {inputValue}) => {
                    return options.filter(option =>
                        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                        option.categoryName.toLowerCase().includes(inputValue.toLowerCase()),
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Tätigkeit"/>}
                  groupBy={(option) => (option?.categoryName || 'Anderes')}
                  onChange={(event, value) => {
                    if (value) {
                      setActivityUID(value.uid);
                    } else {
                      setActivityUID('');
                    }
                  }}
              />
            </FormControl>
          </Box>
        </View>
        <View style={{flex: 1}}>
          <Box sx={{mx: 2, mb: 2}}>
            <FormControl fullWidth>
              <Autocomplete
                  autoHighlight
                  blurOnSelect
                  noOptionsText="Keine Ergebnisse gefunden"
                  options={places}
                  getOptionLabel={(places) => places.name}
                  renderInput={(params) => <TextField {...params} label="Ort"/>}
                  onChange={(event, value) => {
                    if (value) {
                      setPlaceUID(value.uid);
                    } else {
                      setPlaceUID('');
                    }
                  }}
              />
            </FormControl>
          </Box>
        </View>
        <View style={{flex: 1}}>
          <Box sx={{mx: 2, mb: 2}}>
            <FormControl fullWidth>
              <Autocomplete
                  autoHighlight
                  blurOnSelect
                  noOptionsText="Keine Ergebnisse gefunden"
                  options={cultures}
                  getOptionLabel={(cultures) => cultures.name}
                  renderInput={(params) => <TextField {...params} label="Kultur" error={showError} helperText={showError ? 'Kultur darf nicht leer sein.' : ''}/>}
                  onChange={(event, value) => {
                    if (value) {
                      setCultureUID(value.uid);
                    } else {
                      setCultureUID('');
                    }
                  }}
              />
            </FormControl>
          </Box>
        </View>
        <TextField
            label="Bemerkung"
            placeholder="Bemerkung"
            value={comment}
            multiline
            rows={3}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            sx={{mx: 2, mb: 2}}
        />
        <Button style={styles.savebtn} onClick={handleSaveHours} startIcon={<SaveIcon/>}>
          Stunden speichern
        </Button>

        <Dialog open={showOfflineDialog && isConnected} onClose={handleOfflineDialogNo} aria-labelledby="offline-dialog-title" aria-describedby="offline-dialog-description">
          <DialogTitle id="offline-dialog-title">Ungespeicherte Daten</DialogTitle>
          <DialogContent>
            <DialogContentText id="offline-dialog-description">Sie haben ungespeicherte Beratungsstunden. Wollen sie mit dem OVR-Server synchronisieren?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowOfflineDialog(false)} color="primary">
              Nein, löschen
            </Button>
            <Button onClick={handleOfflineDialogYes} color="primary" autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </View>
  );
};

export default ConsultingSessionEnterForm;
