class H {
  // Helper function to format a date as "YYYY-MM-DD"
  /**
   * @param {Date} date
   */
  formatDateToYYYYMMDD(date) {
    const today = new Date();
    const year = date?.getFullYear() ?? today.getFullYear();
    const monthIndex = date?.getMonth() || today.getMonth();
    const month = String(monthIndex + 1).padStart(2, '0');
    const day = String(date?.getDate() ?? today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Helper function to format time as "hh:mm:ss"
  /**
   * @param {string | number} hours
   * @param {string | number} minutes
   */
  formatTimeToHHMMSS(hours, minutes) {
    const hoursInSec = parseInt(hours.toString(), 10) * 60 * 60;
    const minutesInSec = parseInt(minutes.toString(), 10) * 60;
    const totalSeconds = hoursInSec + minutesInSec;

    const hoursPart = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutesPart = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const secondsPart = String(totalSeconds % 60).padStart(2, '0');

    return `${hoursPart}:${minutesPart}:${secondsPart}`;
  }

  /**
   *
   * Generates a FormData-Object or appends a JS-Object to existing FormData
   * @param {{ [x: string]: string | Blob; }} form
   * @param {{ [x: string]: string | Blob; }} object
   * @returns {FormData}
   */
  generateFormData(form, object) {
    let data;
    if (form instanceof FormData) {
      data = form;
      if (Object.keys(object).length !== 0) {
        for (const k in object) {
          data.append(k, object[k]);
        }
      }
    } else {
      data = new FormData();
      for (const key in form) {
        data.append(key, form[key]);
      }
      if (Object.keys(object).length !== 0) {
        for (const key in object) {
          data.append(key, object[key]);
        }
      }
    }
    return data;
  }

  // Helper function to format a date as "YYYY-MM-DD"
  /**
   * @param {Date} date
   */
  static formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Helper function to format time as "hh:mm:ss"
  /**
   * @param {string | number} hours
   * @param {string | number} minutes
   */
  static formatTimeToHHMMSS(hours, minutes) {
    const hoursInSec = parseInt(hours.toString(), 10) * 60 * 60;
    const minutesInSec = parseInt(minutes.toString(), 10) * 60;
    const totalSeconds = hoursInSec + minutesInSec;

    const hoursPart = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutesPart = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const secondsPart = String(totalSeconds % 60).padStart(2, '0');

    return `${hoursPart}:${minutesPart}:${secondsPart}`;
  }

  /**
   *
   * Generates a FormData-Object or appends a JS-Object to existing FormData
   * @param {{ [x: string]: string | Blob; }} form
   * @param {{ [x: string]: string | Blob; }} object
   * @returns {FormData}
   */
  static generateFormData(form, object) {
    let data;
    if (form instanceof FormData) {
      data = form;
      if (Object.keys(object).length !== 0) {
        for (const k in object) {
          data.append(k, object[k]);
        }
      }
    } else {
      data = new FormData();
      for (const key in form) {
        data.append(key, form[key]);
      }
      if (Object.keys(object).length !== 0) {
        for (const key in object) {
          data.append(key, object[key]);
        }
      }
    }
    return data;
  }
  /**
   * Javascript implementation of PHP empty function
   * @param {string | number | object | any[] | any} value
   */
  static isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === 'boolean') {
      return false;
    }
    if (typeof value === 'number') {
      return value === 0;
    }
    if (typeof value === 'string') {
      return value.length === 0;
    }
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    if (typeof value === 'object') {
      // Check for an empty object
      // @ts-ignore
      for (const _ in value) {
        return false;
      }
      return true;
    }
    return false;
  }
  static calculateTimeDifference(startDate, startTime, endDate, endTime) {
    // Parse the start and end date-time strings
    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(`${endDate}T${endTime}`);

    // Calculate the time difference in milliseconds
    // @ts-ignore
    const timeDifferenceMs = endDateTime - startDateTime;

    // Calculate the hours and minutes
    const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  }

  static formatDateString(dateStr) {
    const dateObj = new Date(dateStr);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}.${month}.${year}`;
  }

  /**
   *  Encode user details in Base64 for BasicAuth Header
   */
  static getEncodedCredentials (email, password) {
    const credentials = `${email}:${password}`;

    // Base64 encode the credentials
    const encodedCredentials = btoa(credentials);

    return encodedCredentials;
  }
}

export default H;
