// @ts-nocheck
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import Fab from '@mui/material/Fab';
import {useFocusEffect} from '@react-navigation/native';
import {useCallback, useEffect, useRef, useState} from 'react';
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import ConsultingSessionListItem from '../components/ConsultingSessionList/ConsultingSessionListItem';
import ConsultingSessionMemberContactInfo from '../components/ConsultingSessionList/ConsultingSessionMemberContactInfo';
import DeleteModal from '../components/ConsultingSessionList/DeleteModal';
import MoreOptionsMenu from '../components/ConsultingSessionList/MoreOptionsMenu';
import {SideFilterMenu} from '../components/ConsultingSessionList/SideFilterMenu';
import {useGlobalContext} from '../contexts/globalContext';
import {useDeleteResource} from '../hooks/useDeleteResource';
import {useResource} from '../hooks/useResource';
import SCREEN from '../utils/constants/SCREEN';
import {useTheme} from '@mui/material/styles';


export default function ConsultingSessionList({navigation, route}) {
  const theme = useTheme();

  const styles = StyleSheet.create({
    btnContainer: {
      flexDirection: 'row',
      paddingHorizontal: 20,
      marginTop: 10,
      justifyContent: 'center',
    },
    btn: {
      color: theme.palette.white,
      padding: '10px',
      maxWidth: 350,
      backgroundColor: theme.palette.green,
      transition: 'width 0.5s',
    },
    iconContainer: {
      maxHeight: 50,
    },
    icon: {
      width: 30,
      height: 30,
      padding: 10,
      backgroundColor: '#aeb3b8',
      borderRadius: 0.5,
      color: theme.palette.white,
      justifySelf: 'flex-start',
    },
    footerContainer: {
      position: 'absolute',
      bottom: 16,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingRight: 16,
      alignItems: 'center',
    },
    backButton: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 20,
      color: '#28465A',
      paddingLeft: 10,
    },

    nomatchesContainer: {
      flexDirection: 'row',
      paddingTop: 10,
    },
    nomatches: {
      fontSize: 20,
      textAlign: 'center',
    },
    fade: {},
  });

  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const {auth, setMemberName, memberName, setHistoryMemberID, historyMemberID, showMemberContactInfo, setShowMemberContactInfo} =
      useGlobalContext();
  const defaultConsultingSessionResourceFilter = {
    consultantMemberLoginID: auth.uid,
    memberID: null,
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [selectedRowUid, setSelectedRowUid] = useState(null);
  const [popedOverRow, setPopedOverRow] = useState(null); // popedOverRow = die Zeile die gerade eine Popover "hat"
  const [filterSideMenuOpen, setFilterSideMenuOpen] = useState(false);
  const [memberContactInfo, setMemberContactInfo] = useState({
    shouldShown: false,
    memberID: '',
    name: '',
    adresse: '',
    email: '',
    phone: '',
    areas: '',
    cultureNames: '',
    totalArea: '',
  });
  const [selectedMemberUID, setSelectedMemberUID] = useState(0);

  const {
    data: selectedMemberPersonalData,
    reload: selectedMemberPersonalDataReload,
  } = useResource({
    name: 'memberPersonalData',
    filter: {memberIDs: selectedMemberUID},
  });
  const [consultingSessionResourceFilter, setConsultingSessionResourceFilter] = useState(
      defaultConsultingSessionResourceFilter,
  );

  const flatListRef = useRef(null);

  const [filterFormState, setFilterFormState] = useState({
    member_uid: 0,
    activity_uid: 0,
    place_uid: 0,
    culture_uid: 0,
    startDate: new Date(),
    endDate: new Date(),
  });

  const [sortValue, setSortValue] = useState('standard');

  const handleCopySession = (selectedSession) => {
    const session = selectedSession;
    const memberData = memberPersonalData?.find(
        (item) => item?.memberID === session?.memberID.toString(),
    );

    const rowData = {
      user_uid: session.consultantMemberLoginID,
      member_uid: session.memberID,
      activity_uid: session.activityID,
      place_uid: session.locationID,
      culture_uid: session.cultureID,
      comment: session.comment,
      startDate: session.startDate,
      name: memberData.name,
      hours: session.hours,
      minutes: session.minutes,
    };

    navigation.navigate(SCREEN.COPY_CONSULTING_SESSION, {
      row: rowData,
      activities,
      places,
      cultures,
    });
    setMoreOptionsOpen(false);
  };

  const {
    data: consultingSessions,
    loading: consultingSessionsLoading,
    reload: consultingSessionsReload,
  } = useResource({
    name: 'consulting-sessions',
    enabled: auth.uid,
    cacheName: 'api-sessions-cache',
    filter: consultingSessionResourceFilter,
  });

  const {data: activities, loading: activitiesLoading} = useResource({
    name: 'activities',
    defaultData: [],
    cacheName: 'api-activities-cache',
  });

  const {data: cultures, loading: culturesLoading} = useResource({
    name: 'cultures',
    defaultData: [],
    cacheName: 'api-cultures-cache',
  });

  const {data: places, loading: placesLoading} = useResource({
    name: 'places',
    defaultData: [],
    cacheName: 'api-places-cache',
  });

  // We don't want to fetch member personal data until we have the sessions data
  // Otherwise we would be doing a lot of unnecessary requests
  // That's why we set `enabled` to `!consultingSessionsLoading`
  // And we also want to make sure that we only fetch the member personal data
  // for the members that are on the sessions data.
  // That's why we filter the memberIDs on the sessions data and pass that to the resource fetch.
  const {
    data: memberPersonalData,
    loading: memberPersonalDataLoading,
    reload: memberPersonalDataReload,
  } = useResource({
    name: 'memberPersonalData',
    cacheName: 'api-members-cache',
    enabled: !consultingSessionsLoading,
    defaultData: [],
    filter: {
      memberIDs: consultingSessionsLoading
          ? ''
          : Array.isArray(consultingSessions) &&
          // eslint-disable-next-line prettier/prettier
          consultingSessions?.filter((item) => !!item).map((item) => item?.memberID).join(','),
    },
  });

  const {data: memberPersonalDataAutoComplete, loading: memberPersonalDataAutoCompleteLoading} =
      useResource({
        name: 'memberPersonalDataAutoComplete',
        cacheName: 'api-members-cache',
      });

  const {delete: deleteConsultingSession} = useDeleteResource({name: 'consulting-sessions'});

  // This effect will be triggered when the screen is focused.
  useFocusEffect(
      useCallback(() => {
        consultingSessionsReload();
        memberPersonalDataReload();
        return () => {
          // reset Default Values nach Klick auf Verlauf
          setConsultingSessionResourceFilter(defaultConsultingSessionResourceFilter);
          memberPersonalDataReload();
        };
      }, [consultingSessionsReload, memberPersonalDataReload]),
  );

  useEffect(() => {
    consultingSessionsReload();
  }, [consultingSessionResourceFilter, consultingSessionsReload]);

  useEffect(() => {
    selectedMemberPersonalDataReload();
  }, [selectedMemberUID]);

  useEffect(() => {
    let data = selectedMemberPersonalData[0] ?? null;
    fetchMemberContactInfo(data?.memberID);
  }, [selectedMemberPersonalData]);

  const handleClickAddHours = () => {
    navigation.navigate(SCREEN.ADD_HOURS, {});
  };

  const handleMoreOptionsMenu = (event, item) => {
    memberPersonalDataReload();
    setContextMenuPosition({top: event.clientY, left: event.clientX});
    setMoreOptionsOpen(true);
    setSelectedRowUid(item.uid);
    setPopedOverRow(item);
  };

  const handleEditRow = (event, row) => {
    const rowData = event?.row || row;
    setMoreOptionsOpen(false);
    navigation.navigate(SCREEN.EDIT_CONSULTING_SESSION, {
      row: rowData,
      activities,
      places,
      cultures,
    });
  };

  const handleDelete = (uid) => {
    setDeleteDialogOpen(true);
    setMoreOptionsOpen(false);
    setSelectedRowUid(uid);
  };

  const handleConfirmDelete = () => {
    if (selectedRowUid !== null) {
      const uidToDelete = selectedRowUid;
      setDeleteDialogOpen(false);
      deleteConsultingSession(uidToDelete).then(() => {
        setSelectedRowUid(null);
        setMemberName(null);
        setConsultingSessionResourceFilter(defaultConsultingSessionResourceFilter);
        consultingSessionsReload();
      });
    }
  };

  const handleShowHistory = (event, row) => {
    setMoreOptionsOpen(false);
    setConsultingSessionResourceFilter((prevState) => ({
      consultantMemberLoginID: null,
      memberID: row.memberID,
    }));

    const found = memberPersonalData?.find(
        (item) => item?.memberID === popedOverRow?.memberID.toString(),
    );
    setMemberName(found.name);
    setSelectedMemberUID(found.memberID);
    setShowMemberContactInfo(true);
  };

  const fetchMemberContactInfo = (memberID) => {
    let found = selectedMemberPersonalData[0];

    let foundcultureNames = [];
    let foundcultureAreaSizes = [];
    let foundTotalArea = 0;
    if (found?.areas && Array.isArray(Object.values(found?.areas))) {
      Object.values(found.areas).forEach(({areaSizeHa, cultureName}) => {
        foundcultureNames.push(cultureName);
        foundcultureAreaSizes.push(areaSizeHa);
        foundTotalArea += parseFloat(areaSizeHa || 0);
      });
    }

    setMemberContactInfo({
      memberID: found?.memberID ?? null,
      name: found?.name ?? null,
      address: {
        zip: found?.zip ?? null,
        city: found?.city ?? null,
        street: found?.street ?? null,
        poBox: found?.poBox ?? null,
        zipPoBox: found?.zipPoBox ?? null,
      },
      emails: found?.emails ?? null,
      phones: found?.phones ?? null,
      areas: foundcultureAreaSizes ?? null,
      cultureNames: foundcultureNames ?? null,
      totalArea: foundTotalArea ?? null,
    });
  };

  const handleFilterBtnClick = () => {
    setFilterSideMenuOpen(true);
  };

  const handlePress = () => {
    setMemberName(null);
    setConsultingSessionResourceFilter(defaultConsultingSessionResourceFilter);
    setShowMemberContactInfo(false);
    consultingSessionsReload();
    memberPersonalDataReload();
  };

  // calculate filtered & sorted data
  const filteredSortedSessions = consultingSessions.filter((session) => {
    if (!filterFormState) return true;
    const {activity_uid, culture_uid, place_uid, member_uid} = filterFormState;

    if (!!activity_uid && activity_uid.toString() !== session.activityID.toString()) {
      return false;
    }
    if (!!culture_uid && culture_uid.toString() !== session.cultureID.toString()) {
      return false;
    }
    if (!!place_uid && place_uid.toString() !== session.locationID.toString()) {
      return false;
    }
    return true;
  }).sort((a, b) => {
    const curStartDate = new Date(a.startDate);
    const nexStartDate = new Date(b.startDate);

    if (sortValue === 'date_desc') {
      if (curStartDate.getTime() === nexStartDate.getTime()) {
        return a.uid - b.uid;
      } else {
        return curStartDate - nexStartDate;
      }
    } else if (sortValue === 'date_asc' || sortValue === 'standard') {
      if (curStartDate.getTime() === nexStartDate.getTime()) {
        return b.uid - a.uid;
      } else {
        return nexStartDate - curStartDate;
      }
    }

    if (sortValue === 'lastname_asc') {
      const curMemberID = a.memberID.toString();
      const nexMemberID = b.memberID.toString();
      let curMember;
      let nexMember;

      memberPersonalData.forEach((item) => {
        if (item.memberID.toString() === curMemberID) {
          curMember = item;
        }
        if (item.memberID.toString() === nexMemberID) {
          nexMember = item;
        }
      });

      const curLastName = (curMember?.lastname || curMember?.name || '').toLowerCase();
      const nexLastName = (nexMember?.lastname || nexMember?.name || '').toLowerCase();

      if (curLastName < nexLastName) {
        return -1;
      }

      return 0;
    }

    if (sortValue === 'lastname_desc') {
      const curMemberID = a.memberID.toString();
      const nexMemberID = b.memberID.toString();
      let curMember;
      let nexMember;

      memberPersonalData.forEach((item) => {
        if (item.memberID.toString() === curMemberID) {
          curMember = item;
        }
        if (item.memberID.toString() === nexMemberID) {
          nexMember = item;
        }
      });

      const curLastName = (curMember?.lastname || curMember?.name || '').toLowerCase();
      const nexLastName = (nexMember?.lastname || nexMember?.name || '').toLowerCase();

      if (curLastName > nexLastName) {
        return -1;
      }

      return 0;
    }
  });
  return (
      <SafeAreaProvider>
        <View style={{flex: 1, paddingTop: 0, paddingBottom: 0}}>
          {memberName && (
              <TouchableOpacity onPress={handlePress}>
                <Text style={styles.backButton}>
                  <ArrowBackIosNewOutlinedIcon style={{verticalAlign: 'bottom'}}/>
                  alle anzeigen
                </Text>
              </TouchableOpacity>
          )}
          {showMemberContactInfo && memberContactInfo.memberID && (
              <ConsultingSessionMemberContactInfo memberContactInfo={memberContactInfo}/>
          )}

          <FlatList
              ref={flatListRef}
              data={filteredSortedSessions}
              renderItem={({item}) => {
                const {memberID, activityID, startDate, cultureID, comment} = item;
                const activity = activities?.find(
                    (item) => item?.uid?.toString() === activityID?.toString(),
                );

                const culture = cultures?.find((item) => item?.uid?.toString() === cultureID?.toString());
                const cMLoginID = item?.consultantMemberLoginID;
                const cMEMail = item?.email;

                return (
                    <ConsultingSessionListItem
                        key={item.uid}
                        item={item}
                        member={item.member}
                        memberPersonalDataLoading={memberPersonalDataLoading}
                        activity={activity}
                        culture={culture}
                        comment={comment}
                        consultantMemberLoginID={cMLoginID}
                        cMEMail={cMEMail}
                        handleMoreOptionsMenu={handleMoreOptionsMenu}
                    />
                );
              }}
              keyExtractor={(item, index) => index.toString()}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 90,
              }}
              ListEmptyComponent={
                <View style={styles.nomatchesContainer}>
                  <Text style={styles.nomatches}>Es wurden keine Beratungsstunden gefunden.</Text>
                </View>
              }
          />

          <MoreOptionsMenu
              item={popedOverRow}
              handleEditRow={handleEditRow}
              handleDelete={handleDelete}
              handleShowHistory={handleShowHistory}
              handleCopySession={handleCopySession}
              memberPersonalData={memberPersonalData}
              moreOptionsOpen={moreOptionsOpen}
              setMoreOptionsOpen={setMoreOptionsOpen}
              setContextMenuPosition={setContextMenuPosition}
              contextMenuPosition={contextMenuPosition}
              popedOverRow={popedOverRow}
              selectedRowUid={selectedRowUid}
              setMemberName={setMemberName}
              memberName={memberName}
          />
          <DeleteModal
              deleteDialogOpen={deleteDialogOpen}
              setDeleteDialogOpen={setDeleteDialogOpen}
              setMoreOptionsOpen={setMoreOptionsOpen}
              handleConfirmDelete={handleConfirmDelete}
              setMemberName={setMemberName}
          />
          <View style={styles.footerContainer}>
            <Fab
                color="primary"
                onClick={handleFilterBtnClick}
                style={{marginRight: 16, position: 'relative'}}>
              <TuneIcon/>
              {activeFiltersCount > 0 && (
                  <div
                      style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-4px',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: theme.palette.red,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: theme.palette.white,
                        fontSize: '12px',
                      }}>
                    {activeFiltersCount}
                  </div>
              )}
            </Fab>
            <Fab style={{
              backgroundColor: theme.palette.green,
            }} sx={{
              '& .MuiSvgIcon-root': {
                color: theme.palette.white,
              },
            }} onClick={handleClickAddHours}>
              <AddIcon/>
            </Fab>
          </View>
          {!memberPersonalDataAutoCompleteLoading && (
              <SideFilterMenu
                  anchor="right"
                  open={filterSideMenuOpen}
                  onOpen={() => null}
                  onClose={(latestFilterField, formState, sortValue, isReset = false) => {
                    setFilterFormState(formState);
                    setSortValue(sortValue);
                    if (isReset) {
                      setConsultingSessionResourceFilter(defaultConsultingSessionResourceFilter);
                      setMemberContactInfo({
                        shouldShown: false,
                      });
                      setShowMemberContactInfo(false);
                      //navigation.goBack();
                    } else if (formState.member_uid) {
                      setConsultingSessionResourceFilter({
                        memberID: formState.member_uid,
                      });
                      setSelectedMemberUID(formState.member_uid);
                      setShowMemberContactInfo(true);
                    }

                    // side effect : scroll to top
                    flatListRef.current?.scrollToOffset({offset: 0, animated: true});

                    // side effect : scroll to top
                    let count = 0;
                    if (formState.activity_uid > 0) count++;
                    if (formState.culture_uid > 0) count++;
                    if (formState.place_uid > 0) count++;
                    if (formState.member_uid > 0) count++;
                    setActiveFiltersCount(count);
                    setFilterSideMenuOpen(false);
                  }}
                  activities={activities}
                  cultures={cultures}
                  places={places}
                  memberPersonalDataAutoComplete={memberPersonalDataAutoComplete}
                  memberPersonalDataAutoCompleteLoading={memberPersonalDataAutoCompleteLoading}
              />
          )}
        </View>
      </SafeAreaProvider>
  );
}
