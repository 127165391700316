import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Slide, Snackbar, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as React from 'react';
import { useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import PasswordChecklist from 'react-password-checklist';
import { useClickAway } from 'react-use';
import {useTheme} from '@mui/material/styles';
import { useGlobalContext } from '../contexts/globalContext';
import UserService from '../services/UserService';
import SCREEN from '../utils/constants/SCREEN';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveIcon from '@mui/icons-material/Save';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function Profile({ route, navigation }) {
  const theme = useTheme();
  const style = StyleSheet.create({
    main: {
      maxWidth: 375,
      alignSelf: 'center',
      paddingVertical: 25,
      paddingHorizontal: 15,
    },
    wrapper: {
      borderBottomColor: '#E4E9EC',
      borderBottomWidth: 2,
    },
    mainHeader: {
      color: '#28465A',
      fontSize: 22,
      lineHeight: 22,
    },
    username: {
      color: '#28465A',
      fontSize: 22,
    },
    header: {
      color: '#28465A',
      fontSize: 18,
      lineHeight: 18,
      paddingTop: 20,
      paddingBottom: 20,
    },
    text: {
      marginBottom: 20,
    },
    input: {
      marginBottom: 24,
    },
    nameBanner: {
      textAlign: 'center',
      lineHeight: 22,
      fontSize: 22,
      paddingBottom: 30,
      borderBottomColor: '#E4E9EC',
      borderBottomWidth: 2,
    },
    userData: {
      color: '#28465A',
      fontSize: 18,
      lineHeight: 18,
      paddingTop: 30,
      paddingBottom: 20,
    },
    dataInput: {
      width: 345,
      alignSelf: 'center',
      marginBottom: 15,
    },
    blueButtons: {
      width: 345,
      height: 50,
      color: theme.palette.white,
      backgroundColor: theme.palette.green,
      textTransform: 'none',
    },
    redButtons: {
      width: 345,
      height: 50,
      alignSelf: 'center',
      color: theme.palette.white,
      backgroundColor: theme.palette.red,
      textTransform: 'capitalize',
    },
    logout: {
      marginVertical: 10,
      // alignSelf: 'baseline',
    },
    downloadDisplay: {
      display: 'flex',
      position: 'absolute',
      bottom: 1,
      width: '95%',
      transform: 'translateX(1%)',
      height: 69,
      backgroundColor: '#323231',
      padding: 5,
      borderRadius: 3,
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontSize: 13,
    },
    hide: {
      display: 'none',
    },
  });
  const { auth, setCredentials, setActiveTab } = useGlobalContext();
  const [showSnackbarOffline, setShowSnackbarOffline] = useState(false);
  const [showMessageLoadOffline, setShowMessageLoadOffline] = useState(false);
  const [showSnackbarLoadOfflineDone, setShowSnackbarLoadOfflineDone] = useState(false);
  const [completedRequests, setCompletedRequests] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showSnackbarChangeData, setShowSnackbarChangeData] = useState(false);
  const [showSnackbarError, setShowSnackbarError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [formdata, setFormdata] = useState({
    email: auth.email,
    password: auth.password,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickInput = () => {
    setShowChecklist(true);
  };

  const handleClickAway = (event) => {
    const target = event.target;
    const isIconButton =
      target.id === 'toggle-password-visibility' ||
      target.closest('#toggle-password-visibility') ||
      target.id === 'btn_change_data' ||
      target.closest('#btn_change_data');
    if (!isIconButton) {
      setShowChecklist(false);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, handleClickAway);

  const handleLogoutClick = (event) => {
    event.preventDefault();
    // setCredentials({
    //   id: '',
    //   username: '',
    //   email: '',
    //   password: '',
    // }),
    setActiveTab(0);
    navigation.navigate(SCREEN.LOGIN, {});
  };

  const handleClickChangeData = async () => {
    setShowSnackbarChangeData(true);
    if (!navigator.onLine) {
      setShowSnackbarOffline(true);
      setTimeout(() => setShowSnackbarOffline(false), 3000);
      setShowSnackbarChangeData(false);
      return;
    }
    const SERVICE = new UserService();
    const updateData = {
      uid: auth.uid,
    };
    let emailValid = false;
    let passwordValid = false;

    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (formdata?.email && formdata.email.match(emailRegex)) {
      updateData.email = formdata.email;
      emailValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }

    // eslint-disable-next-line no-useless-escape
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])[A-Za-z\d@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{5,}$/;
    if (formdata?.password && formdata.password.match(passwordRegex)) {
      updateData.password = formdata.password;
      passwordValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }
    if (emailValid && passwordValid) {
      await SERVICE.update(updateData);
      setTimeout(() => setShowSnackbarChangeData(false), 3000);
      setCredentials({...auth, password:formdata?.password})
    }
  };

  const handleFormChange = (event) => {
    setFormdata((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={style.main}>
        <View style={style.nameBanner}>
          <Text style={style.mainHeader}>
            <Text style={style.username} />
            {auth.email ? `UID: ${auth.uid}` : ''}
          </Text>
        </View>
        <View style={style.wrapper}>
          <Text style={style.userData}>Nutzerdaten ändern</Text>
          <View style={style.input}>
            <TextField
              name="email"
              label="E-Mail"
              id="filled-helperText"
              value={formdata.email}
              onChange={handleFormChange}
              style={style.dataInput}
            />
          </View>
          <View style={style.input}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Neues Passwort</InputLabel>
              <OutlinedInput
                value={formdata.password}
                name="password"
                onChange={handleFormChange}
                onClick={handleClickInput}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      id="toggle-password-visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Neues Passwort"
                inputRef={ref}
              />
              {showChecklist && (
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital']}
                  minLength={6}
                  value={formdata.password}
                  onChange={(isValid) => {}}
                  messages={{
                    minLength: <Text>Das Passwort enthält mehr als 5 Zeichen</Text>,
                    specialChar: <Text>Das Passwort enthält Sonderzeichen</Text>,
                    number: <Text>Das Passwort enthält Zahlen</Text>,
                    capital: <Text>Das Passwort enthält Großbuchstaben</Text>,
                  }}
                  style={{ padding: 10 }}
                />
              )}
            </FormControl>
          </View>
          <Button id="btn_change_data" onClick={handleClickChangeData} style={style.blueButtons} startIcon={<SaveIcon/>}>
            Speichern
          </Button>
        </View>
        <View style={style.logout}>
          <Button onClick={handleLogoutClick} style={style.redButtons} startIcon={<LogoutIcon/>}>
            Logout
          </Button>
        </View>
      </ScrollView>
      <View>
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarChangeData}
          TransitionComponent={SlideTransition}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon sx={{ mr: '10px' }} />
              <span>Daten wurden gepeichert</span>
            </div>
          }
          key="snackbar-change-data"
        />
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarError}
          TransitionComponent={SlideTransition}
          key="snackbar-error">
          <Alert severity="error" sx={{ width: '100%' }}>
            Bitte überprüfen Sie Ihre Eingabe!
          </Alert>
        </Snackbar>
      </View>
    </View>
  );
}
