import AsyncStorage from '@react-native-async-storage/async-storage';

class MPStorageService {
  STORAGE_KEY_AUTH = 'SK_AUTH';

  async set(key, stringValue) {
    if (this.isValid(key)) {
      await AsyncStorage.setItem(key, stringValue);
    } else {
      // throw error
    }
  }

  async get(key) {
    return await AsyncStorage.getItem(key);
  }

  /**
   *
   * @param stringValue
   * @returns {Promise<void>}
   */
  async setCredentials(stringValue) {
    await this.set(this.STORAGE_KEY_AUTH, stringValue);
  }

  /**
   * Convenient Method to get the credentials from the storage
   * @returns {object}
   */
  async getCredentials() {
    const credentials = await this.get(this.STORAGE_KEY_AUTH);
    return credentials ? JSON.parse(credentials) : null;
  }

  isValid(key) {
    const validKeys = [this.STORAGE_KEY_AUTH];
    return validKeys.includes(key);
  }
}

export default MPStorageService;
