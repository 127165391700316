import {Image} from 'react-native';

import Beratungsstunden from '../../../assets/icons/beratungsstunden_active.svg'; // Change the import path accordingly

const ActiveBeratungsstundenIcon = ({width, height}) => {
  const _w = width || 30;
  const _h = height || 30;

  const filterStyle = {
    filter:
        'brightness(0) saturate(100%) invert(36%) sepia(43%) saturate(1058%) hue-rotate(109deg) brightness(91%) contrast(101%)',
  };

  return <Image style={{width: _w, height: _h, ...filterStyle}} source={Beratungsstunden}/>;
};

export default ActiveBeratungsstundenIcon;
