import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../contexts/globalContext';

import H from '../helper/helper';
import { BACKEND_API_GATEWAY } from '../utils/constants/BACKEND_API_GATEWAY';
// eslint-disable-next-line import/order

/**
 * A custom React hook that provide an easy way to talk with our REST API 
 * also provides caching capabilities
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.name - The name of the resource.
 * @param {boolean} [options.enabled=true] - Whether the hook is enabled or not.
 * @param {string} [options.method='GET'] - The HTTP method to use for the request.
 * @param {Object} [options.filter={}] - The filter to apply to the request, keys might ends up in the MySQL query as column names.
 * @param {string} [options.cacheName=''] - The name of the cache to use.
 * @param {Array} [options.defaultData=[]] - The default data to return if no data is found, this is stupid I should delete this
 * @returns {Object} - The state and functions related to the resource.
 * @property {string} method - The HTTP method to use for the request, almost always GET
 * @property {Array} data - The data returned by the request.
 * @property {Object} error - The error returned by the request.
 * @property {boolean} loading - Whether the request is currently loading.
 * @property {string} url - The URL of the resource.
 * @property {string} resourceName - The name of the resource.
 * @property {string} baseURL - The base URL of the API.
 * @property {Function} reload - A function to reload the resource using the current axiosConfig.
 */
const useResource = ({
  name,
  enabled = true,
  method = 'GET',
  filter = {},
  cacheName = '',
  defaultData = [],
}) => {
  const isConnected = navigator.onLine;
  const filterRef = useRef(filter);
  let globalAuthState = useGlobalContext.getState().auth;
  /**
   * The state that is returned by the hook
   * this provide an "API" to work with the useResource hook
   */
  const [hookState, setHookState] = useState({
    method,
    data: defaultData, // not to be confused with data to post, this is the resource data to return
    error: null,
    loading: true,
    url: `/${name}`,
    resourceName: name,
    baseURL: BACKEND_API_GATEWAY,
    reload: () => doJob(), // reloads the resource using current axiosConfig in state, exposed to the calling Component
  });

  /**
   * Remember the axiosConfig in state, used when reload is called
   */
  const [axiosConfig, setAxiosConfig] = useState({
    baseURL: BACKEND_API_GATEWAY,
    method,
    url: `/${name}`,
    headers: {
      'Authorization': `Basic ${H.getEncodedCredentials(globalAuthState.email, globalAuthState.password)}`,
    },
    data: {},
  });

  /**
   * Handle the case when filter is changed
   * - calculate new axios config
   * - set new axios config
   * - call doJob with new config as directConfig
   * - (because using axiosConfig without waiting for setAxiosConfig leads to bugs) (!!IMPORTANT)
   */
  useEffect(() => {
    if (enabled) {
      // expose the loading state to caller
      setHookState((previousState) => {
        return { ...previousState, loading: true };
      });

      doJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  /**
   * This hook ensure that doJob accesses the new updated filter when called
   */
  useEffect(() => {
    filterRef.current = filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  /**
   * This function is used to do the actual job
   *
   * @returns
   */
  const doJob = async () => {
    try {
      const filter = filterRef.current;

      // format filter according to axios documentation
      const newConfig = { ...axiosConfig };
      if (!H.isEmpty(filter)) {
        if (method === 'GET') {
          newConfig.url =
            axiosConfig.baseURL + `/${hookState.resourceName}?${objectToQueryString(filter)}`;
        }
        if (method === 'POST') {
          newConfig.data = filter;
        }
        setAxiosConfig(newConfig);
      }

      const isOnline = isConnected;
      const isOnlineWithCacheEnabled = isConnected && cacheName;
      //const isOnlineWithCacheDisnabled = isConnected && !cacheName;
      const isOfflineWithCacheEnabled = !isConnected && cacheName;
      // console.log('filter', filter);
      // console.log('newConfig', newConfig);
      // console.log('isOnline', isOnline);
      // console.log('isOnlineWithCacheEnabled', isOnlineWithCacheEnabled);

      if (isOnline) {
        const response = await axios(newConfig);
        // console.log('response?.data', response?.data);

        setHookState((previousState) => {
          return {
            ...previousState,
            loading: false,
            data: response?.data,
            response,
          };
        });
        if (isOnlineWithCacheEnabled) {
          //save to cache
          setCachedData(newConfig.url, cacheName, response.data);
        }
      } else if (isOfflineWithCacheEnabled) {
        // handle load from offline cache
        const data = await getCachedData(axiosConfig.url, cacheName);

        setHookState((previousState) => {
          return {
            ...previousState,
            loading: false,
            data,
            response: { cacheName, data, isCachedData: true },
          };
        });
      }
    } catch (error) {
      const response = error?.response;
      // Expose the error & loading state to caller
      setHookState((previousState) => {
        return {
          ...previousState,
          loading: false,
          error,
          response,
        };
      });
    }
  };

  return hookState;
};

/**
 * Helper function to convert an object to a query string
 * @param {*} obj
 * @returns
 */
function objectToQueryString(obj) {
  const queryParams = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // key is set on the object not in its prototype...
      const value = obj[key];

      if (value !== null && value !== undefined) {
        // value is defined
        let valueString = Array.isArray(value) ?
          encodeURIComponent(value.reduce((whole, part) => whole ? `${whole},${part}` : part, '')) :
          encodeURIComponent(value);
        queryParams.push(`${encodeURIComponent(key)}=${valueString}`);
      }
    }
  }

  return queryParams.join('&');
}

/**
 *
 * @param {*} url
 * @param {*} cacheName
 * @returns {Promise<any[]>}
 */
async function getCachedData(url, cacheName) {
  if (!caches) return null;

  const cache = await caches.open(cacheName); // Same as defined in Service Worker
  const cachedResponse = await cache.match(url);

  if (!cachedResponse || !cachedResponse.ok) return [];

  const data = await cachedResponse.json();

  return data;
}

/**
 *
 * @param {*} url
 * @param {*} cacheName
 */
async function setCachedData(url, cacheName, data) {
  const cache = await caches.open(cacheName);
  const response = new Response(JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
  });

  await cache.put(url, response);
}

export { useResource };

