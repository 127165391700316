import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { View, SafeAreaView, StyleSheet, FlatList } from 'react-native';

const styles = StyleSheet.create({
  listItemTextStyles: {
    width: '200px',
    fontWeight: 'bold',
    wordwrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'inherit',
  },

  textStyles: {
    paddingLeft: 20,
  },
});

function MoreOptionsMenu({
  item,
  handleEditRow,
  handleDelete,
  handleShowHistory,
  handleCopySession,
  memberPersonalData,
  moreOptionsOpen,
  setMoreOptionsOpen,
  setContextMenuPosition,
  contextMenuPosition,
  popedOverRow,
  selectedRowUid,
  setMemberName,
  memberName,
}) {
  const found = memberPersonalData?.find(
    (item) => item?.memberID === popedOverRow?.memberID.toString(),
  );

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={contextMenuPosition}
      open={moreOptionsOpen && popedOverRow?.uid.toString() === item?.uid.toString()}
      onClose={() => setMoreOptionsOpen(false)}>
      <MenuItem>
        <ListItemText primaryTypographyProps={styles.listItemTextStyles}>
          {found ? found.name + ` ID: [${found.memberID}]` : 'Name nicht gefunden'}
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={(event) => {
          handleEditRow(event, popedOverRow);
        }}>
        <EditIcon />
        <ListItemText style={styles.textStyles}>Ändern</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleCopySession(popedOverRow)}>
        <ContentCopyIcon />
        <ListItemText style={styles.textStyles}>Duplizieren</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDelete(selectedRowUid);
        }}>
        <DeleteIcon />
        <ListItemText style={styles.textStyles}>Löschen</ListItemText>
      </MenuItem>
      {!memberName && (
        <MenuItem
          onClick={(event) => {
            handleShowHistory(event, popedOverRow);
          }}>
          <HistoryIcon />
          <ListItemText style={styles.textStyles}>Verlauf</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}

export default MoreOptionsMenu;
