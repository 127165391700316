import axios from 'axios';
import { useGlobalContext } from '../contexts/globalContext';

import { BACKEND_API_GATEWAY } from '../utils/constants/BACKEND_API_GATEWAY';
import H from "../helper/helper"

class MPRestService {
  restClient;
  authHeader = null;

  constructor(authHeader=null) {
    this.authHeader = authHeader;
    this.restClient = axios.create({
      baseURL: BACKEND_API_GATEWAY,
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
        'X-Custom-Header': 'Esteburg',
        'Authorization':`Basic ${authHeader}`,
      },
    });
  }

  /**
   * An async function to patch data to the given endpoint.
   *
   * @param {string} endpoint - the endpoint to patch data to
   * @param {object} patchData - the data to be patched
   * @return  the response data from the patch operation, or null if an error occurs
   */
  async _patch(endpoint, patchData) {
    this.updateSecurityHeader();
    try {
      const response = await this.restClient.patch(endpoint, patchData);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  /**
   * @param {string} endpoint
   * @param {{ consultingActivity?: any; email?: any; password?: any; }} postData
   */
  async _post(endpoint, postData) {
    this.updateSecurityHeader();
    try {
      const response = await this.restClient.post(endpoint, postData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * A description of the entire function.
   *
   * @param {any} endpoint
   * @param {any} queryParams
   * @return description of return value
   */
  async _get(endpoint, queryParams) {
    this.updateSecurityHeader();
    try {
      const response = await this.restClient.get(endpoint, { params: queryParams });
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  async _delete(endpoint, queryParams) {
    this.updateSecurityHeader();
    try {
      const response = await this.restClient.delete(endpoint, { params: queryParams });
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  handleError(error) {
    if (error.response) {
      // The server did not response with a 2xx Code
    } else if (error.request) {
      // No response was received
    } else {
      // Keine Ahnung
    }
  }

  updateSecurityHeader(){
    if(this.authHeader === null){
      let globalAuthState = useGlobalContext.getState().auth;
      this.restClient.defaults.headers['Authorization']=`Basic ${H.getEncodedCredentials(globalAuthState.email, globalAuthState.password)}`;
    }
  }
}

export default MPRestService;
