import React, { createContext, useState } from 'react';

export const AppContext = createContext(null);

/**
 *
 * @param {{children}} props
 * @returns
 */
export const AppContextProvider = ({ children }) => {
  const _authContext = {
    username: '',
    password: '',
    id: '',
    email: '',
    name: '',
    last_name: '',
  };

  const _context = {
    auth: { ..._authContext },
  };

  const [appContext, setAppContext] = useState(_context);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <AppContext.Provider
      value={{
        appContext,
        setAppContext,
        setActiveTab,
        activeTab,
      }}>
      {children}
    </AppContext.Provider>
  );
};
