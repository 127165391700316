import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import Icon from '../../../assets/images/header-img.jpg';
import { useGlobalContext } from '../../contexts/globalContext';
import SCREEN from '../../utils/constants/SCREEN';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 80,
    position: 'relative',
    backgroundImage: `url(${Icon})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: 10,
    paddingLeft: 10,
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 10,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  backButton: {
    fontSize: 20,
    color: '#28465A',
  },
});

const FadeInHeaderImage = ({ heading, navigation }) => {
  const { setActiveTab } = useGlobalContext();

  const handlePress = () => {
    navigation.navigate(SCREEN.HOME, {});
    setActiveTab(0);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.backButton}>
          <ArrowBackIosNewOutlinedIcon style={{ verticalAlign: 'bottom' }} />
          zurück
        </Text>
      </TouchableOpacity>
      <View style={styles.textContainer}>
        <Text style={styles.heading}>{heading}</Text>
      </View>
    </View>
  );
};

export default FadeInHeaderImage;
