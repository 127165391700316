import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import ConsultingActivityService from '../../services/ConsultingActivityService';
import {useState} from 'react';
import {StyleSheet} from 'react-native';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ConsultingActivitiesDeleteModal({row, open, onClose, activitiesReload}) {
  const styles = StyleSheet.create({
    btngroup: {
      justifyContent: 'center',
      paddingBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
    }
  });

  const handleDelete = async () => {
    try {
      const res = await ConsultingActivityService.deleteConsultingActivity(row.uid);
      activitiesReload();
      onClose();
    } catch (error) {
      console.error(error);
    }

  };

  return (
      <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Datensatz löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie <strong>{row.name}</strong> wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.btngroup}>
          <Button  variant="contained" color="primary" onClick={onClose} startIcon={<CancelIcon />}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete} startIcon={<DeleteIcon/>}>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
  );

}

