const MAX_OPTIONS = 100; // Maximale Anzahl der angezeigten Optionen

// Filterfunktion, die auf 100 Elemente begrenzt
const filterMemberOptions = (options, { inputValue }) => {
  const inputValueLower = inputValue.toLowerCase();
  const filteredOptions = options.filter((option) => {
    const nameMatch = option.name && option.name.toLowerCase().includes(inputValueLower);
    const streetMatch = option.street && option.street.toLowerCase().includes(inputValueLower);
    const cityMatch = option.city && option.city.toLowerCase().includes(inputValueLower);
    const zipMatch = option.zip && option.zip.toLowerCase().includes(inputValueLower);
    const IDMatch = option.memberID && option.memberID.toLowerCase().includes(inputValueLower);
    return nameMatch || streetMatch || cityMatch || zipMatch || IDMatch;
  });
  return filteredOptions.slice(0, MAX_OPTIONS);
};

export default filterMemberOptions;
