export default function destroyIOSStupidScroll(window) {
  const handleTouchmove = (event) => {
    const startY = localStorage.getItem('startY') || 0;
    let el = event.target;

    // Allow zooming
    const zoom = window.innerWidth / window.document.documentElement.clientWidth;
    if (event.touches.length > 1 || zoom !== 1) {
      return;
    }

    // Recursive check up the dom tree, starting from the touched element
    while (el !== document.body && el !== document) {
      const style = window.getComputedStyle(el);
      if (!style) {
        break;
      }

      if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
        return;
      }

      const scrolling = style.getPropertyValue('-webkit-overflow-scrolling');
      const overflowY = style.getPropertyValue('overflow-y');
      const height = parseInt(style.getPropertyValue('height'), 10);

      const shouldScroll =
        scrolling === 'touch' && (overflowY === 'auto' || overflowY === 'scroll');
      const canScroll = el.scrollHeight > el.offsetHeight;
      if (shouldScroll && canScroll) {
        const curY = event.touches ? event.touches[0].screenY : event.screenY;
        const isAtTop = startY <= curY && el.scrollTop === 0;
        const isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop === height;
        if (isAtTop || isAtBottom) {
          event.preventDefault();
        }

        // handled scrolling, stop going up
        return;
      }

      // not handled scrolling, going up the DOM
      el = el.parentNode;
    }

    // prevent bounce when overscroll in iOS
    // we reached this line because there was no scrolling element
    // we dont reach this line when we handled a scrolling element above
    event.preventDefault();
  };

  const handleTouchstart = (event) => {
    localStorage.setItem('startY', event.touches ? event.touches[0].screenY : event.screenY);
  };

  // Let'sa go, Mario!
  window.addEventListener('touchstart', handleTouchstart, { passive: false });
  window.addEventListener('touchmove', handleTouchmove, { passive: false });
}
