import MPRestService from './MPRestService';

class AuthService extends MPRestService {
  endpoint;

  constructor(authHeader = "") {
    super(authHeader);
    this.endpoint = '/auth';
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   * @returns {Promise<{isAuthorized: any, uid: any, email: any, password: any}>} authData
   */
  async login(email, password) {
    const data = {
      email,
      password,
    };

    try {
      return await this._post(this.endpoint, data);
    } catch (e) {
      throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
    }
  }
}

export default AuthService;
